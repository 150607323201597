<template>
  <b-container fluid>
    <b-card class="p-0">
      <form @submit.prevent>
        <b-card-body class="p-0 m-0">
          <b-row>
            <b-col cols="8">
              <b-row>
                <b-col cols="2" class="m-0 p-0">
                  <b-form-input v-model="_searchData.invoice_id" placeholder="Fatura Numarası Giriniz"/>
                </b-col>
                <b-col cols="2" class="m-0 p-0">
                  <b-form-input v-model="_searchData.document_id" placeholder="Döüman ID Giriniz"/>
                </b-col>

                <b-col cols="2" class="m-0 p-0">
                  <v-select
                      v-model="_searchData.company_id"
                      label="short_name"
                      multiple
                      :options="_companyList"
                      :reduce="item => item.id"
                      class="select-size"
                      placeholder="Firma"
                      :closeOnSelect="false"
                  />
                </b-col>

                <b-col cols="2" class="m-0 p-0">
                  <v-select
                      v-model="_searchData.current_id"
                      label="name"
                      multiple
                      :options="_currentList"
                      :reduce="item => item.id"
                      class="select-size"
                      placeholder="Cari"
                      :closeOnSelect="false"
                  />
                </b-col>

                <b-col cols="2" class="m-0 p-0">
                  <v-select
                      v-model="_searchData.envelope_status"
                      label="value"
                      multiple
                      :options="_status"
                      :reduce="item => item.statu"
                      class="select-size"
                      placeholder="Durum 2"
                      :closeOnSelect="false"
                  />
                </b-col>

                <b-col cols="2" class="m-0 p-0">
                  <v-select
                      v-model="_searchData.status"
                      label="value"
                      multiple
                      :options="_status"
                      :reduce="item => item.statu"
                      class="select-size"
                      placeholder="Durum"
                      :closeOnSelect="false"
                  />
                </b-col>

                <b-col cols="2" class="m-0 p-0">
                  <v-select
                      v-model="_searchData.scenario"
                      label="name"
                      multiple
                      :options="_scenario"
                      :reduce="item => item.value"
                      class="select-size"
                      placeholder="Kime"
                      :closeOnSelect="false"
                  />
                </b-col>

                <b-col cols="2" class="m-0 p-0">
                  <v-select
                      v-model="_searchData.invoice_tip_type"
                      label="name"
                      multiple
                      :options="_invoice_tip_type"
                      :reduce="item => item.value"
                      class="select-size"
                      placeholder="Fatura Tipi"
                      :closeOnSelect="false"
                  />
                </b-col>

                <b-col cols="2" class="m-0 p-0">
                  <v-select
                      v-model="_searchData.type"
                      label="name"
                      multiple
                      :options="_invoice_type"
                      :reduce="item => item.value"
                      class="select-size"
                      placeholder="Tip"
                      :closeOnSelect="false"
                  />
                </b-col>

                <b-col cols="2" class="m-0 p-0">
                  <b-form-input v-model="_searchData.target_title" placeholder="Müşteri Adı"/>
                </b-col>

                <b-col cols="2" class="m-0 p-0">
                  <b-form-input v-model="_searchData.target_tckn_vkn" placeholder="Vergi No"/>
                </b-col>

                <b-col cols="2" class="m-0 p-0">
                  <flat-pickr
                      v-model="_searchData.date"
                      class="form-control"
                      placeholder="Fatura Tarihi"
                      :config="dateConfig"
                  />
                </b-col>

                <b-col cols="2" class="m-0 p-0">
                  <v-select
                      v-model="_searchData.limit"
                      :options="[1,5,10,20,50,100,250,500,1000,2500,10000]"
                      class="select"
                      placeholer="Limit"
                  />
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="2">
              <b-row>
                <b-col cols="12">
                  <b-form-radio-group
                      v-model="_searchData.is_new"
                      :options="[
                            { text: 'Yeni', value: 1 },
                            { text: 'Eski', value: 0 },
                            { text: 'Tüm', value: null },
                        ]"
                      class="demo-inline-spacing"
                      name="is_new_radio"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-radio-group
                      v-model="_searchData.in_out"
                      :options="[
                            { text: 'Gelen', value: 1 },
                            { text: 'Giden', value: 2 },
                        ]"
                      class="demo-inline-spacing"
                      name="in_out-radio"
                  />
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="2">
              <b-row>
                <b-col cols="12">
                  <b-button-group size="sm">
                    <b-button variant="primary" @click="getInvoiceList(1)">
                      <feather-icon icon="FilterIcon"/>
                    </b-button>
                    <b-button variant="danger">
                      <feather-icon icon="XCircleIcon"/>
                    </b-button>
                    <b-dropdown right variant="primary">
                      <template #button-content>
                        <feather-icon icon="DownloadIcon"/>
                      </template>
                      <b-dropdown-item @click="getInvoiceUyum(1)">Gök Bilişim</b-dropdown-item>
                      <b-dropdown-item @click="getInvoiceUyum(2)">Gz Ticaret</b-dropdown-item>
                    </b-dropdown>
                  </b-button-group>
                </b-col>

              </b-row>


            </b-col>
          </b-row>
          <Pagination :pagination="_pagination" @pageClick="getInvoiceList"/>
        </b-card-body>
      </form>
    </b-card>
    <router-view/>
  </b-container>
</template>

<script>
import {mapGetters} from "vuex";
import Pagination from '@/components/Pagination'
import vSelect from "vue-select";
import flatPickr from 'vue-flatpickr-component'
import {Turkish} from 'flatpickr/dist/l10n/tr.js';

export default {
  name: "InvoiceHome",
  components: {Pagination, vSelect, flatPickr},
  data: () => ({
    dateConfig: {
      mode: 'range',
      locale: Turkish,
    },
  }),
  computed: {
    ...mapGetters('invoice', ["_invoiceListCount", "_pagination", "_searchData", "_pagination", "_status", "_scenario", "_invoice_tip_type", "_invoice_type"]),
    ...mapGetters('company', ["_companyList"]),
    ...mapGetters('current', ["_currentList"])
  },
  methods: {
    getInvoiceList(page) {
      this._searchData.page = page
      this.$store.dispatch('invoice/getInvoiceList')
    },
    getInvoiceUyum(companyId) {
      console.log(this.$route.name);
      let invoiceType = this.$route.name == 'fatura-gelen' ? 'gelen' : 'giden';
      this.$store.dispatch('invoice/getInvoiceUyum', {companyId, invoiceType})
    }
  },
  created() {
    this.$store.dispatch('company/getCompanyList');
    this.$store.dispatch('current/getCompanyList')
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
